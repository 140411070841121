import { FunctionComponent, useState } from 'react';

import { useSigneringApi } from '@common/api/signeringApi';
import { ApiRequestStatus } from '@common/api/useApi';
import ErrorMessage from '@common/basics/ErrorMessage';
import SpinnerHW from '@common/basics/spinner/SpinnerHW';
import { useRequiredParams } from '@common/routing/useRequiredParams';
import SigningContainer from '@common/signing/SigningContainer';
import { fetchRecentlySignedJob } from '../../jobs/completedJobs/recentlySignedJobs';
import Confirmation from './Confirmation';
import { useI18n } from '@common/language/i18n';

const ConfirmationPage: FunctionComponent = () => {
  const [translation] = useI18n();
  const { id } = useRequiredParams(['id']);
  const [padesRefreshFailed, setPadesRefreshFailed] = useState(false);
  const { request } = useSigneringApi({
    dataFetcher: fetchRecentlySignedJob,
    urlArgs: [id],
    options: {
      refetchInterval: (data, query) => {
        const linkNotReady = data?.jobCompletedAt && !data?.pades?.link;
        if (query.state.dataUpdateCount < 6 && linkNotReady) {
          return (
            1000 * (query.state.dataUpdateCount * query.state.dataUpdateCount)
          );
        } else {
          if (linkNotReady) {
            setPadesRefreshFailed(true);
          }
          return false;
        }
      },
    },
  });

  switch (request.status) {
    case ApiRequestStatus.IDLE:
    case ApiRequestStatus.PENDING:
      return (
        <SigningContainer>
          <SpinnerHW />
        </SigningContainer>
      );
    case ApiRequestStatus.SUCCESS:
      return (
        <Confirmation
          job={request.result}
          padesRefreshFailed={padesRefreshFailed}
        />
      );
    case ApiRequestStatus.FAILURE:
      return <ErrorMessage message={translation.portal.job.loading_failed} />;
  }
};

export default ConfirmationPage;
