import { FunctionComponent } from 'react';

import heroIllustration from '@images/hero/enkel-signering-hero-svg.svg';
import Heading from '@common/pageBuildingBlocks/Heading';
import { useI18n } from '@common/language/i18n';
import { FRONTPAGE_CLICK } from '../organization/constants';
import { useTracking } from '@common/analytics/Analytics';
import { linkToInternalSite } from '@common/navigation/externalNavigation';

const HeroSection: FunctionComponent = () => {
  const [translation] = useI18n();
  const { registerAnalyticsEvent } = useTracking();

  return (
    <article
      className={`hero hw-block--bg-white hw-block--mt-large-3-responsive hw-block--mb-large-4-responsive`}
    >
      <div className={`hero__content`}>
        <Heading
          headerText={translation.common.frontpage.heading}
          extraClassName="text-center--mobile"
        />
        <p className="hw-block--mt hero__text">
          {translation.common.frontpage.heading_subtext}
        </p>
        <div className="hw-flex fc__large--fd-row  hw-one-whole"></div>
        <div className="landingpage__CTA-buttons">
          <a
            href={linkToInternalSite('/privat').href}
            className="hw-button hw-button--primary hw-button--mobile-full"
            data-testid="landingpage-send-document"
            onClick={() => {
              registerAnalyticsEvent({
                category: 'Landingsside',
                action: FRONTPAGE_CLICK,
                label: 'Gå til privatpersonside',
              });
            }}
          >
            {translation.common.frontpage.cta.private}
          </a>
          <a
            href={linkToInternalSite('/virksomhet').href}
            className="hw-button hw-button--outline-secondary hw-button--mobile-full landingpage__CTA-button--secondary"
            onClick={() => {
              registerAnalyticsEvent({
                category: 'Landingsside',
                action: FRONTPAGE_CLICK,
                label: 'Gå til virksomhetsside',
              });
            }}
          >
            {translation.common.frontpage.cta.register}
          </a>
        </div>
      </div>
      <img
        className="hero__image"
        src={heroIllustration}
        alt={translation.common.frontpage.hero.image_text}
      />
    </article>
  );
};

export default HeroSection;
