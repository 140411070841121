import { FunctionComponent } from 'react';

import PostenHeader from '@common/header/PostenHeader';
import SigningInstructions from './SigningInstructions';
import HeaderRightContent from '@common/header/HeaderRightContent';
import Hamburger from '@common/header/Hamburger/Hamburger';
import HelpLink from '@common/help/HelpLink';
import HamburgerElement from '@common/header/Hamburger/HamburgerElement';
import HeaderLogOutButton from '@common/header/HeaderLogOutButton';
import { AppName } from '@common/analytics/appNames';
import { useI18n } from '@common/language/i18n';
import { useTracking } from '@common/analytics/Analytics';
import { HelpTopicUrl } from '@common/help/helpTopic';
import { App } from '../../apps';
import { HELP_URL } from '@common/help/helpUrls';

const PortalHeader: FunctionComponent<{ inSigningProcess?: boolean }> = ({
  inSigningProcess = false,
}) => {
  const [translation] = useI18n();
  const { registerAnalyticsEvent } = useTracking();

  return (
    <PostenHeader inSigningProcess={inSigningProcess} homeLink="/">
      <HeaderRightContent
        loggedIn={true}
        app={App.SIGNATURE_PORTAL}
        goToDifiHelp={false}
        linkToDefaultTopic={HelpTopicUrl.sign}
        url={HELP_URL}
        appName={AppName.SIGNER}
      >
        <SigningInstructions
          extraClassName="navbar__menu-item navbar__menu_item-button"
          id="signing-instructions-title"
        />
        <a
          href="/virksomhet"
          className="navbar__menu-item"
          data-testid="portal-navbar-organization-button"
          onClick={() =>
            registerAnalyticsEvent({
              category: App.SIGNATURE_PORTAL,
              action: 'Trykket på Virksomhet i header',
              label: 'Gikk til avsenderportal fra undertegnerportal',
            })
          }
        >
          {translation.portal.menu.organization}
        </a>
      </HeaderRightContent>
      <Hamburger>
        <HamburgerElement>
          <SigningInstructions
            extraClassName="hamburger__list-element__button hamburger__list-item__button-text posten-sans-bold"
            id="signing-instructions-title-mobile"
          />
        </HamburgerElement>
        <HamburgerElement>
          <a
            href="/virksomhet"
            className="hamburger__list-element__button hamburger__list-item__button-text posten-sans-bold"
            onClick={() =>
              registerAnalyticsEvent({
                category: App.SIGNATURE_PORTAL,
                action: 'Trykket på Virksomhet i header',
                label: 'Gikk til avsenderportal fra undertegnerportal',
              })
            }
          >
            {translation.portal.menu.organization}
          </a>
        </HamburgerElement>
        <HamburgerElement>
          <HelpLink
            goToDifiHelp={false}
            openInNewTab={false}
            url={HELP_URL}
            linkToDefaultTopic={HelpTopicUrl.sign}
            appName={AppName.SIGNER}
            className="hamburger__list-element__button hamburger__list-item__button-text posten-sans-bold"
          />
        </HamburgerElement>
        <HamburgerElement>
          <HeaderLogOutButton
            app={App.SIGNATURE_PORTAL}
            extraClassName="hamburger__list-element__button hamburger__list-item__button-text posten-sans-bold"
          />
        </HamburgerElement>
      </Hamburger>
    </PostenHeader>
  );
};

export default PortalHeader;
