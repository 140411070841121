import { FunctionComponent } from 'react';
import { SIGNED_SUCCESSFULLY_ACTION_NAME } from './analyticsTools';
import { useTracking } from '@common/analytics/Analytics';
import { buttonStyles, DownloadDocumentButton, widths } from '@hedwig/buttons';
import Heading from '@common/pageBuildingBlocks/Heading';
import { useI18n } from '@common/language/i18n';
import { AppName } from '@common/analytics/appNames';
import { App } from '../../../apps';
import mobileIllustration from '@images/signing/mobil_brev.svg';
import LoadingDocumentSpinner from './LoadingDocumentSpinner';
import ToSigningPortalLink from './ToSigningPortalLink';
import { convertUrlToAnalyticsLabel } from '../../forwarding/RegisterMailboxLink';

const Upsale: FunctionComponent<UpsaleProps> = ({
  padesLink,
  bodyText,
  registerButtonText,
  registerButtonUrl,
  isCompleted,
  padesRefreshFailed,
}) => {
  const [translation] = useI18n();
  const { registerAnalyticsEvent } = useTracking();

  return (
    <>
      <Heading
        headerText={translation.portal.signing.signed.success}
        sizeClass="hw-h2"
      />
      <div className="hw-block--mt-large-2">
        <img src={mobileIllustration} alt="" />
        {isCompleted && (
          <div className="hw-block--mt-large-2">
            {padesRefreshFailed ? (
              <p className="confirmation-text">
                {translation.portal.signing.signed.refresh_failed}
              </p>
            ) : padesLink ? (
              <DownloadDocumentButton
                downloadLink={padesLink}
                text={translation.portal.signing.signed.signed_document}
                dataTestid="signed-document-link-when-upsale"
                buttonStyle={buttonStyles.primary}
                width={widths.responsive}
                extraClasses="confirmation-page-button"
                category={AppName.SIGNER}
                analyticsLabel="Rett etter signering i undertegnerportal"
              />
            ) : (
              <LoadingDocumentSpinner />
            )}
          </div>
        )}
        <div className="hw-block--mt-large-2">
          <p
            className="hw-block--mb-medium-2 confirmation-text"
            id="mailbox-info-text"
            data-testid="mailbox-info-text"
          >
            {bodyText}
          </p>
          <a
            target="_blank"
            tabIndex={0}
            ref={(domElement) => {
              if (domElement) domElement.focus();
            }}
            rel="noopener noreferrer"
            className="hw-button hw-button--outline-secondary"
            href={registerButtonUrl}
            id="link-to-register-mailbox"
            data-testid="link-to-register-mailbox"
            onClick={() =>
              registerAnalyticsEvent({
                category: App.SIGNATURE_PORTAL,
                action: SIGNED_SUCCESSFULLY_ACTION_NAME,
                label: convertUrlToAnalyticsLabel(registerButtonUrl),
              })
            }
          >
            {registerButtonText}
          </a>
        </div>
      </div>
      <ToSigningPortalLink />
    </>
  );
};

interface UpsaleProps {
  bodyText: string;
  registerButtonText: string;
  registerButtonUrl: string;
  padesLink?: string;
  isCompleted: boolean;
  padesRefreshFailed: boolean;
}

export default Upsale;
