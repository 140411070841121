import {
  buttonStyles,
  DownloadDocumentButton,
  sizes,
  widths,
} from '@hedwig/buttons';
import { FunctionComponent } from 'react';
import Heading from '@common/pageBuildingBlocks/Heading';
import { useI18n } from '@common/language/i18n';
import { AppName } from '@common/analytics/appNames';
import mobileIllustration from '@images/signing/mobil_brev.svg';
import LoadingDocumentSpinner from './LoadingDocumentSpinner';
import ToSigningPortalLink from './ToSigningPortalLink';

const Forwarded: FunctionComponent<ForwardedProps> = ({
  padesLink,
  bodyText,
  isCompleted,
  padesRefreshFailed,
}) => {
  const [translation] = useI18n();

  return (
    <>
      <Heading
        sizeClass="hw-h2"
        headerText={translation.portal.signing.signed.success}
        dataTestid="signing-completed-and-forwarded-heading"
      />
      <img src={mobileIllustration} alt="" className="hw-block--mt-medium-2" />
      {isCompleted && (
        <div className="hw-block--mt-large-2">
          {padesRefreshFailed ? (
            <p className="confirmation-text">
              {translation.portal.signing.signed.refresh_failed}
            </p>
          ) : padesLink ? (
            <DownloadDocumentButton
              downloadLink={padesLink}
              text={translation.portal.signing.signed.signed_document}
              dataTestid="signed-document-link-when-forwarded"
              width={widths.normal}
              size={sizes.small}
              buttonStyle={buttonStyles.primary}
              extraClasses="confirmation-page-button"
              category={AppName.SIGNER}
              analyticsLabel="Rett etter signering i undertegnerportal"
            />
          ) : (
            <LoadingDocumentSpinner />
          )}
        </div>
      )}
      <p
        className="hw-block--mt-large-2 confirmation-text"
        dangerouslySetInnerHTML={{ __html: bodyText }}
        id="mailbox-info-text"
        data-testid="mailbox-info-text"
      />
      <ToSigningPortalLink />
    </>
  );
};

interface ForwardedProps {
  padesLink?: string;
  bodyText: string;
  isCompleted: boolean;
  padesRefreshFailed: boolean;
}

export default Forwarded;
