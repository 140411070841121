import { FunctionComponent } from 'react';
import { useI18n, withParams } from '../language/i18n';
import HelpContent from './HelpContent';

const VALIDITY_ID = 'gyldighet';

const SignHelp: FunctionComponent = () => {
  const [translation] = useI18n();
  return (
    <>
      <HelpContent
        title={translation.common.help.topics.sign.sub_heading_1}
        helpSubTopics={[
          {
            name: 'elektronisk-id',
            headingTextKey:
              translation.common.help.topics.sign.which_eid_heading,
            bodyTextKeys: [translation.common.help.topics.sign.which_eid_body],
          },
          {
            name: 'hvordan-signere',
            headingTextKey:
              translation.common.help.topics.sign.how_to_sign_heading,
            bodyTextKeys: [
              translation.common.help.topics.sign.how_to_sign_body_p1,
            ],
          },
          {
            name: 'info-mangler',
            headingTextKey:
              translation.common.help.topics.sign.info_missing_heading,
            bodyTextKeys: [
              translation.common.help.topics.sign.info_missing_body_1,
              translation.common.help.topics.sign.info_missing_body_2,
            ],
          },
          {
            name: 'får-ikke-signert',
            headingTextKey:
              translation.common.help.topics.sign.cannot_sign_heading,
            bodyTextKeys: [
              translation.common.help.topics.sign.cannot_sign_body_1,
              translation.common.help.topics.sign.cannot_sign_body_2,
            ],
          },
          {
            name: 'utgått-frist',
            headingTextKey:
              translation.common.help.topics.sign.deadline_heading,
            bodyTextKeys: [translation.common.help.topics.sign.deadline_body],
          },
          {
            name: 'avbryte',
            headingTextKey: translation.common.help.topics.sign.reject_heading,
            bodyTextKeys: [translation.common.help.topics.sign.reject_body],
          },
        ]}
      />
      <HelpContent
        title={translation.common.help.topics.sign.sub_heading_2}
        helpSubTopics={[
          {
            name: 'signert-dokument',
            headingTextKey:
              translation.common.help.topics.sign
                .access_signed_document_signer_heading,
            bodyTextKeys: [
              translation.common.help.topics.sign
                .access_signed_document_signer_body,
            ],
          },
          {
            name: 'nedlasting',
            headingTextKey:
              translation.common.help.topics.sign
                .access_signed_document_download_signer_heading,
            bodyTextKeys: [
              translation.common.help.topics.sign
                .access_signed_document_download_signer_body,
            ],
          },
          {
            name: 'nedlasting-frist',
            headingTextKey:
              translation.common.help.topics.sign.availability_heading,
            bodyTextKeys: [
              translation.common.help.topics.sign.availability_body_1,
              translation.common.help.topics.sign.availability_body_2,
            ],
          },
          {
            name: 'mangler-signatur',
            headingTextKey:
              translation.common.help.topics.sign.missing_signature_heading,
            bodyTextKeys: [
              translation.common.help.topics.sign.missing_signature_body_p1,
              translation.common.help.topics.sign.missing_signature_body_p2,
              translation.common.help.topics.sign.missing_signature_body_p3,
            ],
          },
          {
            name: 'digital-signatur',
            headingTextKey: translation.common.help.topics.sign.what_is_heading,
            bodyTextKeys: [
              translation.common.help.topics.sign.what_is_body_p1,
              translation.common.help.topics.sign.what_is_body_p2,
              translation.common.help.topics.sign.what_is_body_p3,
            ],
          },
          {
            name: VALIDITY_ID,
            headingTextKey:
              translation.common.help.topics.archive_and_validation
                .validation_heading,
            bodyTextKeys: [
              translation.common.help.topics.archive_and_validation
                .validation_body_visual_1,
              translation.common.help.topics.archive_and_validation
                .validation_body_visual_2,
              translation.common.help.topics.archive_and_validation
                .tech_validation_body_p1,
              translation.common.help.topics.archive_and_validation
                .tech_validation_body_p2,
              translation.common.help.topics.archive_and_validation
                .tech_validation_body_p3,
            ],
          },
          {
            name: 'printing',
            headingTextKey:
              translation.common.help.topics.archive_and_validation.print
                .heading,
            bodyTextKeys: [
              withParams(
                translation.common.help.topics.archive_and_validation.print
                  .body,
                { validityHref: `#${VALIDITY_ID}` }
              ),
            ],
          },
        ]}
      />
    </>
  );
};

export default SignHelp;
