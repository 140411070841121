import { FunctionComponent } from 'react';
import { useI18n } from '@common/language/i18n';

import digitalSignering from '../../../images/digital_signature/digital_signatur.svg';
import printer from '../../../images/digital_signature/printer.svg';
import manSigningOnTablet from '../../../images/digital_signature/man_signing_on_tablet.svg';
import womanSigningOnLaptop from '../../../images/digital_signature/woman_signing_on_laptop.svg';

import './aboutDigitalSignatures.less';

const Illustration = ({ src }: { src: string }) => {
  return (
    <img
      className="about-digital-signatures__illustration"
      alt=""
      aria-hidden="true"
      src={src}
    />
  );
};

const AboutDigitalSignatures: FunctionComponent = () => {
  const [translation] = useI18n();

  return (
    <section className="about-digital-signatures__section">
      <div className="about-digital-signatures__banner hw-background-color-primary-lighter">
        <div className="hw-container">
          <div className="about-digital-signatures__cautionContainer">
            <div className="about-digital-signatures__textSection">
              <h1 className="about-digital-signatures__heading hw-color-primary-darker">
                {translation.common.help.digital_signatures.heading}
              </h1>
              <p>{translation.common.help.digital_signatures.subheading}</p>
            </div>
            <Illustration src={digitalSignering} />
          </div>
        </div>
      </div>
      <div className="hw-container">
        <h2 className="about-digital-signatures__heading hw-color-primary-darker hw-h1 about-digital-signatures__cautionsHeading">
          {translation.common.help.digital_signatures.cautions.heading}
        </h2>
        <div className="about-digital-signatures__cautionContainer about-digital-signatures__illustrationLeft">
          <div className="about-digital-signatures__textSection">
            <h3 className="about-digital-signatures__heading hw-color-primary-darker hw-h1">
              {
                translation.common.help.digital_signatures.cautions.printing
                  .heading
              }
            </h3>
            <p>
              {
                translation.common.help.digital_signatures.cautions.printing
                  .body
              }
            </p>
          </div>
          <Illustration src={printer} />
        </div>
        <div className="about-digital-signatures__cautionContainer hw-background-color-primary-lighter about-digital-signatures__indent">
          <div className="about-digital-signatures__textSection">
            <h3 className="about-digital-signatures__heading hw-h1 hw-color-primary-darker">
              {
                translation.common.help.digital_signatures.cautions
                  .remove_front_page.heading
              }
            </h3>
            <p>
              {
                translation.common.help.digital_signatures.cautions
                  .remove_front_page.body
              }
            </p>
          </div>
          <Illustration src={manSigningOnTablet} />
        </div>
        <div className="about-digital-signatures__cautionContainer about-digital-signatures__illustrationLeft">
          <div className="about-digital-signatures__textSection">
            <h3 className="about-digital-signatures__heading hw-h1 hw-color-primary-darker">
              {
                translation.common.help.digital_signatures.cautions.compressing
                  .heading
              }
            </h3>
            <p>
              {
                translation.common.help.digital_signatures.cautions.compressing
                  .body
              }
            </p>
          </div>
          <Illustration src={womanSigningOnLaptop} />
        </div>
      </div>
    </section>
  );
};

export default AboutDigitalSignatures;
