import { FunctionComponent, useEffect, useState } from 'react';

import { useToggleScrollContext } from '@common/scroll/AppWithScrollToggle';
import CustomModal from '@common/basics/Modal/CustomModal';
import { addClassesFromProps } from '@common/basicFunctions';
import { useI18n } from '@common/language/i18n';
import { Button, buttonStyles } from '@hedwig/buttons';
import { CloseIcon } from '@hedwig/icons';
import computerCheckMark from '@images/frontpage/computer-check-mark.svg';
import signedDocument from '@images/frontpage/signed-document.svg';
import lock from '@images/frontpage/lock.svg';
import arrow from '@images/instruction_icons/arrow.svg';
import RegisterMailboxLink, {
  RegisterMailboxUrl,
} from '../forwarding/RegisterMailboxLink';

import './signing-instructions.less';

const SigningInstructions: FunctionComponent<{
  extraClassName?: string;
  id: string;
}> = ({ extraClassName, id }) => {
  const [signingInstructionVisibility, setSigningInstructionVisibility] =
    useState(false);

  const [translation] = useI18n();

  const { setDisableScroll } = useToggleScrollContext();
  useEffect(
    () => setDisableScroll(signingInstructionVisibility),
    [setDisableScroll, signingInstructionVisibility]
  );

  return (
    <>
      <button
        onClick={() =>
          setSigningInstructionVisibility(!signingInstructionVisibility)
        }
        className={`${addClassesFromProps(extraClassName)} ${
          signingInstructionVisibility ? 'button-header-tab-active' : ''
        }
        }`}
        type="button"
      >
        <span id={id}>{translation.portal.menu.how}</span>
      </button>

      <CustomModal
        isOpen={signingInstructionVisibility}
        width="60%"
        onClick={() => setSigningInstructionVisibility(false)}
        ariaLabelledby={id}
        ariaDescribedby="signing-instructions-description"
      >
        <div className="instruction-banner">
          <div className="instruction-section-wrapper">
            <Button
              buttonStyle={buttonStyles.transparent}
              extraClassName="button-default-disable modal-button__close"
              onClick={() =>
                setSigningInstructionVisibility(!signingInstructionVisibility)
              }
              ariaLabel={translation.portal.instructions.close}
            >
              <CloseIcon />
            </Button>
            <div className="instruction-section">
              <h3 className="ui-max">
                {translation.portal.instructions.read_doc.header}
              </h3>
              <img
                src={computerCheckMark}
                alt=""
                aria-hidden="true"
                className="instruction-icon"
              />
              <p
                id="signing-instructions-description"
                className="instruction-text hw-text-small"
              >
                {translation.portal.instructions.read_doc.text}
              </p>
            </div>
            <div className="arrow-wrapper">
              <img
                src={arrow}
                alt=""
                aria-hidden="true"
                className="image-arrow-icon"
              />
            </div>
            <div className="instruction-section">
              <h3 className="ui-max">
                {translation.portal.instructions.sign.header}
              </h3>
              <img
                src={lock}
                alt=""
                aria-hidden="true"
                className="instruction-icon"
              />
              <p className="instruction-text hw-text-small">
                {translation.portal.instructions.sign.text}
              </p>
            </div>
            <div className="arrow-wrapper">
              <img
                src={arrow}
                alt=""
                aria-hidden="true"
                className="image-arrow-icon"
              />
            </div>
            <div className="instruction-section">
              <h3 className="ui-max">
                {translation.portal.instructions.archive.header}
              </h3>
              <img
                src={signedDocument}
                alt=""
                aria-hidden="true"
                className="instruction-icon"
              />{' '}
              <p className="instruction-text hw-text-small">
                <span>{translation.portal.instructions.archive.text}</span>
                <RegisterMailboxLink
                  text="norge.no"
                  url={RegisterMailboxUrl.PUBLIC_MAILBOX}
                  extraClassName="hw-link--small"
                  analyticsAction="Så signeringsinstruksjonen"
                />
              </p>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default SigningInstructions;
