import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { useI18n } from '@common/language/i18n';
import Heading from '@common/pageBuildingBlocks/Heading';
import SigningContainer from '@common/signing/SigningContainer';

const SigningUnsuccesfulConfirmation: FunctionComponent = () => {
  const [translation] = useI18n();

  return (
    <SigningContainer>
      <div>
        <Heading
          headerText={translation.portal.signing.failed}
          sizeClass="hw-h2"
          extraClassName="hw-block--mb-medium-4"
          dataTestid="signing-failed-confirmation"
        />
        <div className="img-breven-grumpy file-icon-big" />
        <div>
          <Link
            to="/oppdrag/"
            className="hw-button hw-button--primary button-air"
          >
            {translation.portal.signing.signed.back_to_jobs}
          </Link>
        </div>
      </div>
    </SigningContainer>
  );
};

export default SigningUnsuccesfulConfirmation;
