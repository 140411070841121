import { FunctionComponent } from 'react';
import { useI18n } from '@common/language/i18n';
import SpinnerHW from '@common/basics/spinner/SpinnerHW';
import './confirmation-page.less';

const LoadingDocumentSpinner: FunctionComponent = () => {
  const [translation] = useI18n();
  return (
    <div className="loading-document hw-block--mb-medium-3">
      <SpinnerHW />
      <span className="margin-left__small-2">
        {translation.portal.signing.signed.loading_document}
      </span>
    </div>
  );
};

export default LoadingDocumentSpinner;
