import { FunctionComponent } from 'react';

import { useTracking } from '@common/analytics/Analytics';
import { App } from '../../apps';
import { addClassesFromProps } from '@common/basicFunctions';

export enum RegisterMailboxUrl {
  DIGIPOST = 'https://www.digipost.no/registrering/person',
  PUBLIC_MAILBOX = 'https://www.norge.no/nb/velg-digital-postkasse',
}

export const convertUrlToAnalyticsLabel = (buttonUrl: string) => {
  switch (buttonUrl) {
    case RegisterMailboxUrl.PUBLIC_MAILBOX:
      return 'Trykket på opprett offentlig postkasse';
    case RegisterMailboxUrl.DIGIPOST:
      return 'Trykket på opprett Digipost-konto';
  }
};

const RegisterMailboxLink: FunctionComponent<RegisterLinkProps> = ({
  id,
  text,
  url,
  analyticsAction,
  extraClassName,
}) => {
  const { registerAnalyticsEvent } = useTracking();

  return (
    <a
      id={id}
      data-testid={id}
      onClick={() =>
        registerAnalyticsEvent({
          category: App.SIGNATURE_PORTAL,
          action: analyticsAction,
          label: convertUrlToAnalyticsLabel(url),
        })
      }
      target="_blank"
      href={url}
      className={`hw-link${addClassesFromProps(extraClassName)}`}
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );
};

interface RegisterLinkProps {
  id?: string;
  text: string;
  url: string;
  analyticsAction: string;
  extraClassName?: string;
}

export default RegisterMailboxLink;
