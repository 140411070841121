import { FunctionComponent, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import ElectronicIdProviderList from '@common/electronic-id/ElectronicIdProviderList';
import { OrganizationSector } from '@common/organizations/organizationSector';
import {
  bankIDmobilSignering,
  bankIDSignering,
  buyPassSignering,
} from '@common/electronic-id/electronicIdConstants';
import SigningContainer from '@common/signing/SigningContainer';
import SignPage, { useUnsignedSignatureJob } from './SignPage';
import { useI18n } from '@common/language/i18n';

const ChooseElectronicId: FunctionComponent = () => {
  const [translation] = useI18n();
  const navigate = useNavigate();
  const signatureJobToSign = useUnsignedSignatureJob();

  const onClickProvider = useCallback(
    (eID: string) => {
      navigate(`../signer/${eID}`);
    },
    [navigate]
  );

  const electronicIdProviders =
    signatureJobToSign.sector === OrganizationSector.PRIVATE
      ? [
          bankIDmobilSignering(translation.common.electronic_id.eid),
          bankIDSignering(translation.common.electronic_id.eid),
          buyPassSignering(translation.common.electronic_id.eid),
        ]
      : [
          bankIDSignering(translation.common.electronic_id.eid),
          buyPassSignering(translation.common.electronic_id.eid),
        ];
  return (
    <SigningContainer>
      <h1 className="hw-h2" data-testid="choose-eid-heading">
        {translation.portal.signing.choose_eid}
      </h1>
      <ElectronicIdProviderList
        onClick={onClickProvider}
        electronicProviders={electronicIdProviders}
      />
      <div className="buttonlink-group">
        <Link className="hw-link" to={'/oppdrag/'}>
          {translation.portal.signing.cancel}
        </Link>
      </div>
    </SigningContainer>
  );
};

const ChooseElectronicIdWrapper: FunctionComponent = () => (
  <SignPage>
    <ChooseElectronicId />
  </SignPage>
);

export default ChooseElectronicIdWrapper;
