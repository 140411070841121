import { FunctionComponent, useEffect } from 'react';
import useNavigateExternal from './useNavigateExternal';

const NavigateExternal: FunctionComponent<{
  to: string;
  keepQueryParams?: boolean;
}> = ({ to, keepQueryParams = false }) => {
  const navigateExternal = useNavigateExternal();

  useEffect(() => {
    navigateExternal(to, keepQueryParams);
  }, [to, keepQueryParams, navigateExternal]);
  return null;
};

export default NavigateExternal;
