import { FunctionComponent } from 'react';
import { useI18n } from '@common/language/i18n';
import { Link } from 'react-router-dom';

const ToSigningPortalLink: FunctionComponent = () => {
  const [translation] = useI18n();
  return (
    <p className="hw-block--mt-large-2">
      <span className="fa fa-arrow-right illustrated-text__primary" />
      <Link id="back-to-jobs-button" className="hw-link" to="/oppdrag/">
        {translation.portal.signing.signed.back_to_jobs}
      </Link>
    </p>
  );
};

export default ToSigningPortalLink;
