import { FunctionComponent } from 'react';

import SignPage, { useUnsignedSignatureJob } from './SignPage';
import AuthenticatedSignature from '@common/signing/authenticated/AuthenticatedSignature';

const AuthenticatedSignatureForPortalSignatureJob = () => {
  const signatureJobToSign = useUnsignedSignatureJob();

  return (
    <AuthenticatedSignature
      createSignatureFrameUrl={signatureJobToSign.links.createSignatureFrame}
      cancelPath="../../../"
    />
  );
};

const AuthenticatedSignatureDocumentContainerWrapper: FunctionComponent =
  () => (
    <SignPage>
      <AuthenticatedSignatureForPortalSignatureJob />
    </SignPage>
  );

export default AuthenticatedSignatureDocumentContainerWrapper;
