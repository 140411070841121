import { FunctionComponent } from 'react';

import IDPortenLoginOption from '@common/login/IDPortenLoginOption';
import PrivateLoginOption from '@common/login/PrivateLoginOption';
import { SecurityLevel } from '@common/idporten/securityLevel';
import { Target } from '@common/session/session';
import { useI18n } from '@common/language/i18n';
import Heading from '@common/pageBuildingBlocks/Heading';
import { App } from '../../apps';

import '@common/login/login.less';

const LoginPage: FunctionComponent = () => {
  const [translation] = useI18n();

  return (
    <div className="hw-container hw-container--pt hw-container--pb login-page-container">
      <Heading headerText={translation.portal.login.heading} />
      <div className="login-options-container">
        <IDPortenLoginOption
          target={Target.SIGNER_TARGET}
          app={App.SIGNATURE_PORTAL}
          headingText={translation.portal.login.public_doc}
          exampleText={translation.portal.login.public_examples}
          buttonText={translation.portal.login.public_button}
          securityLevel={SecurityLevel.THREE}
        />
        <PrivateLoginOption
          app={App.SIGNATURE_PORTAL}
          headingText={translation.portal.login.private_doc}
          exampleText={translation.portal.login.private_examples}
          buttonText={translation.portal.login.private_button}
        />
      </div>
    </div>
  );
};

export default LoginPage;
