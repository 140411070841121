import { useEffect, FunctionComponent, useState, useMemo } from 'react';

import Simple from './Simple';
import Forwarded from './Forwarded';
import Upsale from './Upsale';
import SigningContainer from '@common/signing/SigningContainer';
import { SIGNED_SUCCESSFULLY_ACTION_NAME } from './analyticsTools';
import { useTracking } from '@common/analytics/Analytics';
import { useI18n } from '@common/language/i18n';
import ManualForwardingToDigipost from './ManualForwardingToDigipost';
import {
  ForwardSignedDocumentStatus,
  convertForwardSignedDocumentStatusToAnalyticsLabel,
} from '../../forwarding/forwardSignedDocumentStatus';
import { App } from '../../../apps';
import { RecentlySignedJob } from '../../jobs/completedJobs/recentlySignedJobs';
import { RegisterMailboxUrl } from '../../forwarding/RegisterMailboxLink';

import './confirmation-page.less';

const Confirmation: FunctionComponent<{
  job: RecentlySignedJob;
  padesRefreshFailed: boolean;
}> = ({ job, padesRefreshFailed }) => {
  const [translation] = useI18n();
  const { registerAnalyticsEvent } = useTracking();

  const [hasRegisteredEvent, setHasRegisteredEvent] = useState(false);
  const isLastSigner = useMemo(
    () => job.numberOfSigners === job.numberOfSignedSigners,
    [job.numberOfSigners, job.numberOfSignedSigners]
  );

  useEffect(() => {
    if (!hasRegisteredEvent) {
      registerAnalyticsEvent({
        category: App.SIGNATURE_PORTAL,
        action: SIGNED_SUCCESSFULLY_ACTION_NAME,
        label: convertForwardSignedDocumentStatusToAnalyticsLabel(
          job.forwardSignedDocument.status
        ),
      });
      setHasRegisteredEvent(true);
    }
  }, [job, hasRegisteredEvent, setHasRegisteredEvent, registerAnalyticsEvent]);

  switch (job.forwardSignedDocument.status) {
    case ForwardSignedDocumentStatus.CAN_BE_FORWARDED_TO_PUBLIC_MAILBOX_BUT_IS_NOT_REGISTERED:
      return (
        <SigningContainer>
          <Upsale
            padesLink={job.pades?.link}
            bodyText={
              translation.portal.signing.signed.without_mailbox.body.public
            }
            registerButtonText={
              translation.portal.signing.signed.without_mailbox.register.public
            }
            registerButtonUrl={RegisterMailboxUrl.PUBLIC_MAILBOX}
            padesRefreshFailed={padesRefreshFailed}
            isCompleted={isLastSigner}
          />
        </SigningContainer>
      );

    case ForwardSignedDocumentStatus.CAN_BE_FORWARDED_TO_DIGIPOST_BUT_IS_NOT_REGISTERED:
      return (
        <SigningContainer>
          <Upsale
            padesLink={job.pades?.link}
            bodyText={
              translation.portal.signing.signed.without_mailbox.body.private
            }
            registerButtonText={
              translation.portal.signing.signed.without_mailbox.register.private
            }
            registerButtonUrl={RegisterMailboxUrl.DIGIPOST}
            isCompleted={isLastSigner}
            padesRefreshFailed={padesRefreshFailed}
          />
        </SigningContainer>
      );

    case ForwardSignedDocumentStatus.UNKNOWN:
      return (
        <SigningContainer>
          <Upsale
            padesLink={job.pades?.link}
            bodyText={translation.portal.signing.signed.unknown_mailbox.body}
            registerButtonText={
              translation.portal.signing.signed.without_mailbox.register.public
            }
            registerButtonUrl={RegisterMailboxUrl.PUBLIC_MAILBOX}
            isCompleted={isLastSigner}
            padesRefreshFailed={padesRefreshFailed}
          />
        </SigningContainer>
      );

    case ForwardSignedDocumentStatus.WILL_BE_FORWARDED_TO_PUBLIC_MAILBOX:
    case ForwardSignedDocumentStatus.HAS_BEEN_FORWARDED_TO_PUBLIC_MAILBOX:
      return (
        <SigningContainer>
          <Forwarded
            padesLink={job.pades?.link}
            bodyText={
              translation.portal.signing.signed.with_mailbox.body.public
            }
            isCompleted={isLastSigner}
            padesRefreshFailed={padesRefreshFailed}
          />
        </SigningContainer>
      );

    case ForwardSignedDocumentStatus.WILL_BE_FORWARDED_TO_DIGIPOST:
    case ForwardSignedDocumentStatus.HAS_BEEN_FORWARDED_TO_DIGIPOST:
      return (
        <SigningContainer>
          <Forwarded
            padesLink={job.pades?.link}
            bodyText={
              translation.portal.signing.signed.with_mailbox.body.private
            }
            isCompleted={isLastSigner}
            padesRefreshFailed={padesRefreshFailed}
          />
        </SigningContainer>
      );

    case ForwardSignedDocumentStatus.WILL_NOT_BE_FORWARDED:
    case ForwardSignedDocumentStatus.CAN_BE_MANUALLY_FORWARDED_TO_DIGIPOST_BUT_IS_NOT_REGISTERED:
    case ForwardSignedDocumentStatus.COULD_NOT_FORWARD_TO_PUBLIC_MAILBOX:
    case ForwardSignedDocumentStatus.COULD_NOT_FORWARD_TO_DIGIPOST:
      return (
        <SigningContainer>
          <Simple
            padesLink={job.pades?.link}
            isCompleted={isLastSigner}
            padesRefreshFailed={padesRefreshFailed}
          />
        </SigningContainer>
      );

    case ForwardSignedDocumentStatus.CAN_BE_MANUALLY_FORWARDED_TO_DIGIPOST:
      return (
        <SigningContainer>
          <ManualForwardingToDigipost
            padesLink={job.pades?.link}
            forwardLink={job.forwardSignedDocument?.href}
            isCompleted={isLastSigner}
            padesRefreshFailed={padesRefreshFailed}
          />
        </SigningContainer>
      );
    default:
      return (
        <SigningContainer>
          <Simple
            padesLink={job.pades?.link}
            isCompleted={isLastSigner}
            padesRefreshFailed={padesRefreshFailed}
          />
        </SigningContainer>
      );
  }
};

export default Confirmation;
