import { Navigate } from 'react-router-dom';
import DevModeLogInForm from '@common/devMode/DevModeLogInForm';
import { Target } from '@common/session/session';
import { FunctionComponent } from 'react';
import { useSession } from '@common/session/AppWithSession';
import hasAccessToSignerPortal from './login/hasAccessToSignerPortal';

const DevMode: FunctionComponent = () => {
  const { session, devModeLogin } = useSession();

  if (hasAccessToSignerPortal(session)) {
    return <Navigate replace to="../oppdrag" />;
  }

  return (
    <div>
      <div className="content-wrapper content-wrapper-first">
        <DevModeLogInForm
          target={Target.SIGNER_TARGET}
          heading="sluttbruker"
          login={devModeLogin}
        />
      </div>
      <div className="content-wrapper">
        <div className="box">
          <h2 className="hw-text-title">Andre utviklings-innloggingssider</h2>
          <ul className="hw-list simple-list">
            <li>
              <a href="../../virksomhet/dev" className="hw-link">
                Tjenesteeier-portal
              </a>
            </li>
            <li>
              <a href="../admin/dev" className="hw-link">
                Posten/Difi admin-portal
              </a>
            </li>
          </ul>
          <h2 className="hw-h2">Vanlig ID-porten innlogging</h2>
          <ul className="hw-list simple-list">
            <li>
              <a href="../logginn" className="hw-link">
                Sluttbruker-portal
              </a>
            </li>
            <li>
              <a href="../../virksomhet/logginn" className="hw-link">
                Tjeneseteeier-portal
              </a>
            </li>
            <li>
              <a href="../admin/logginn" className="hw-link">
                Posten/Difi adminportal
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DevMode;
