import { FunctionComponent } from 'react';
import { DownloadDocumentButton } from '@hedwig/buttons';
import { useI18n } from '@common/language/i18n';
import Heading from '@common/pageBuildingBlocks/Heading';
import { AppName } from '@common/analytics/appNames';
import signingIllustration from '@images/signing/digital_signering.svg';
import LoadingDocumentSpinner from './LoadingDocumentSpinner';
import ToSigningPortalLink from './ToSigningPortalLink';

const Simple: FunctionComponent<Props> = ({
  padesLink,
  isCompleted,
  padesRefreshFailed,
}) => {
  const [translation] = useI18n();

  return (
    <>
      <Heading
        headerText={translation.portal.signing.signed.success}
        sizeClass="hw-h2"
      />
      <img src={signingIllustration} alt="" className="hw-block--mt-medium-2" />

      {isCompleted ? (
        <div className="hw-block--mt-large-2">
          {padesRefreshFailed ? (
            <p className="confirmation-text">
              {translation.portal.signing.signed.refresh_failed}
            </p>
          ) : padesLink ? (
            <>
              <p
                id="mailbox-info-text"
                className="hw-block--mb-medium-2 confirmation-text"
                data-testid="no-forwarding-body-part-1"
              >
                <span id="no-forwarding-body-part-1">
                  {
                    translation.portal.signing.signed
                      .no_forwarding_body_completed
                  }
                </span>
              </p>
              <DownloadDocumentButton
                dataTestid="signed-document-link-simple"
                extraClasses="confirmation-page-button"
                downloadLink={padesLink}
                text={translation.portal.signing.signed.download_document}
                category={AppName.SIGNER}
                analyticsLabel="Rett etter signering i undertegnerportal"
              />
            </>
          ) : (
            <p
              id="mailbox-info-text"
              className="confirmation-text"
              data-testid="no-forwarding-body-part-1"
            >
              <LoadingDocumentSpinner />
              <span id="no-forwarding-body-part-1">
                {translation.portal.signing.signed.no_forwarding_body_completed}
              </span>
            </p>
          )}
        </div>
      ) : (
        <p
          id="mailbox-info-text"
          className="hw-block--mt-large-2 confirmation-text"
          data-testid="no-forwarding-body-part-1"
        >
          <span id="no-forwarding-body-part-1">
            {
              translation.portal.signing.signed
                .no_forwarding_body_partially_signed
            }
          </span>
        </p>
      )}
      <ToSigningPortalLink />
    </>
  );
};

interface Props {
  padesLink?: string;
  isCompleted: boolean;
  padesRefreshFailed: boolean;
}

export default Simple;
