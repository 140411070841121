import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
} from 'react';

import Spinner from '@common/basics/Spinner';
import ErrorMessage from '@common/basics/ErrorMessage';
import { useRequiredParams } from '@common/routing/useRequiredParams';
import { useSigneringApi } from '@common/api/signeringApi';
import { ApiRequestStatus } from '@common/api/useApi';
import {
  fetchUnsignedSignatureJob,
  UnsignedSignatureJob,
} from '../jobs/unsignedSignatureJob';
import { useI18n } from '@common/language/i18n';

const SignPageContext = createContext<{ job?: UnsignedSignatureJob }>({});

const SignPage: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [translation] = useI18n();
  const { id } = useRequiredParams(['id']);
  const { request } = useSigneringApi({
    dataFetcher: fetchUnsignedSignatureJob,
    urlArgs: [id],
  });

  return (
    <>
      <div className="content-wrapper content-wrapper-first content-wrapper-no-pad">
        <Spinner condition={request.status === ApiRequestStatus.PENDING} />
        {request.status === ApiRequestStatus.FAILURE && (
          <ErrorMessage message={translation.portal.job.loading_failed} />
        )}
      </div>

      {request.status === ApiRequestStatus.SUCCESS && (
        <SignPageContext.Provider value={{ job: request.result }}>
          {children}
        </SignPageContext.Provider>
      )}
    </>
  );
};

export const useUnsignedSignatureJob = () => {
  const { job } = useContext(SignPageContext);
  return job as UnsignedSignatureJob;
};

export default SignPage;
