import { FunctionComponent } from 'react';
import { DownloadDocumentButton, buttonStyles } from '@hedwig/buttons';
import { useI18n } from '@common/language/i18n';
import { IconColor } from '@hedwig/icons';
import Heading from '@common/pageBuildingBlocks/Heading';
import ForwardSignedDocumentToDigipostButton from '../../jobs/completedJobs/forwardSignedDocumentToDigipost/ForwardSignedDocumentToDigipostButton';
import { AppName } from '@common/analytics/appNames';
import signingIllustration from '@images/signing/digital_signering.svg';
import mobileIllustration from '@images/signing/mobil_brev.svg';
import LoadingDocumentSpinner from './LoadingDocumentSpinner';
import ToSigningPortalLink from './ToSigningPortalLink';

const ManualForwardingToDigipost: FunctionComponent<Props> = ({
  padesLink,
  forwardLink,
  isCompleted,
  padesRefreshFailed,
}) => {
  const [translation] = useI18n();

  return (
    <>
      <Heading
        headerText={translation.portal.signing.signed.success}
        sizeClass="hw-h2"
      />
      {isCompleted ? (
        <>
          <img
            src={mobileIllustration}
            alt=""
            className="hw-block--mt-medium-2"
          />
          <p
            id="mailbox-info-text"
            className="hw-block--mb-medium-2 hw-block--mt-large-2 confirmation-text"
          >
            <span
              data-testid="manual-forwarding-body"
              dangerouslySetInnerHTML={{
                __html:
                  translation.portal.signing.signed.can_be_manually_forwarded
                    .text,
              }}
            />
          </p>
          <ForwardSignedDocumentToDigipostButton
            dataTestid="manual-forwarding-button"
            text={
              translation.portal.signing.signed.can_be_manually_forwarded.button
            }
            forwardLink={forwardLink}
            extraClasses="confirmation-page-button"
            view="Signering fullført"
            extraButtonTextClasses="forwarding-status"
          />
          {padesRefreshFailed ? (
            <p className="hw-block--mt-large-2 confirmation-text">
              {translation.portal.signing.signed.refresh_failed}
            </p>
          ) : padesLink ? (
            <DownloadDocumentButton
              dataTestid="signed-document-link-simple"
              downloadLink={padesLink}
              text={translation.portal.signing.signed.download_document}
              buttonStyle={buttonStyles['secondary-outlined']}
              iconColor={IconColor.PRIMARY_DARKER}
              extraClasses="confirmation-page-button hw-block--mt-medium-3"
              category={AppName.SIGNER}
              analyticsLabel="Rett etter signering i undertegnerportal"
            />
          ) : (
            <LoadingDocumentSpinner />
          )}
        </>
      ) : (
        <>
          <img
            src={signingIllustration}
            alt=""
            className="hw-block--mt-medium-2"
          />
          <p
            id="mailbox-info-text"
            className="hw-block--mb-large-2 hw-block--mt-large-2 confirmation-text"
            data-testid="no-forwarding-body-part-1"
          >
            <span data-testid="manual-forwarding-body">
              {
                translation.portal.signing.signed
                  .no_forwarding_body_partially_signed
              }
            </span>
          </p>
        </>
      )}
      <ToSigningPortalLink />
    </>
  );
};

interface Props {
  padesLink?: string;
  forwardLink?: string;
  isCompleted: boolean;
  padesRefreshFailed: boolean;
}

export default ManualForwardingToDigipost;
