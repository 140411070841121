import { FunctionComponent, useEffect, useMemo } from 'react';

import { useTracking } from '@common/analytics/Analytics';
import { useSession } from '@common/session/AppWithSession';
import HeroSection from './HeroSection';
import HowOthersUse from './HowOthersUse';
import WhyPostenSignering from './WhyPostenSignering';
import hasAccessToSignerPortal from '../portal/login/hasAccessToSignerPortal';

import './landing-page.less';

const LandingPage: FunctionComponent = () => {
  const { registerAnalyticsEvent } = useTracking();
  const { session } = useSession();
  const loggedIn = useMemo(() => hasAccessToSignerPortal(session), [session]);

  useEffect(() => {
    if (!loggedIn) {
      registerAnalyticsEvent({
        category: 'Landingsside',
        action: 'Landingsside vist',
      });
    }
  }, [loggedIn, registerAnalyticsEvent]);

  return (
    <section className="hw-container">
      <HeroSection />
      <WhyPostenSignering />
      <HowOthersUse />
    </section>
  );
};

export default LandingPage;
