import { FunctionComponent } from 'react';

import eSignatureIllustration from '@images/landing/pen-signing-on-laptop.svg';
import shakingHandsIllustration from '@images/landing/padlock.svg';
import { useI18n } from '@common/language/i18n';
import manUsingSmartphoneIllustration from '@images/landing/man-using-smartphone.svg';

import './landing-page.less';

interface CardProps {
  image: string;
  title: string;
  text: string;
}

const Card: FunctionComponent<CardProps> = ({ image, title, text }) => {
  return (
    <div className="why-posten-signering__card">
      <img
        className="hw-card__media why-posten-signering__card-image hw-background-color-white hw-block--mb-small-4"
        src={image}
        alt=""
      />
      <div className="hw-card hw-card__body">
        <h3 className="hw-card__title why-posten-signering__card-title">
          {title}
        </h3>
        <p className="hw-card__description">{text}</p>
      </div>
    </div>
  );
};

const WhyPostenSignering: FunctionComponent = () => {
  const [translation] = useI18n();

  return (
    <article>
      <h2 className="hw-h2 hw-color-primary-darker hw-block--mb-medium-4--responsive hw-block--mt-medium-1 text-center--mobile heading--mobile">
        {translation.common.frontpage.why_posten_signering.heading}
      </h2>
      <div className="hw-flex hw-flex--thirds hw-flex--gutter-large-desktop">
        <Card
          image={eSignatureIllustration}
          title={translation.common.frontpage.why_posten_signering.sub_heading1}
          text={translation.common.frontpage.why_posten_signering.text1}
        />
        <Card
          image={shakingHandsIllustration}
          title={translation.common.frontpage.why_posten_signering.sub_heading2}
          text={translation.common.frontpage.why_posten_signering.text2}
        />
        <Card
          image={manUsingSmartphoneIllustration}
          title={translation.common.frontpage.why_posten_signering.sub_heading3}
          text={translation.common.frontpage.why_posten_signering.text3}
        />
      </div>
    </article>
  );
};

export default WhyPostenSignering;
