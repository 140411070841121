import { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import SignicatIframe from '@common/signicat/SignicatIframe';
import { useI18n } from '@common/language/i18n';
import { useRequiredParams } from '@common/routing/useRequiredParams';
import SignPage, { useUnsignedSignatureJob } from './SignPage';
import { LOGGED_IN_HOME_PAGE_URL } from '../index';
import { useToggleScrollContext } from '@common/scroll/AppWithScrollToggle';
import { OrganizationSector } from '@common/organizations/organizationSector';
import Heading from '@common/pageBuildingBlocks/Heading';
import { useSigneringApi } from '@common/api/signeringApi';
import createSignicatIframeLink from '@common/signicat/createSignicatIframeLink';
import { ApiRequestStatus, isLoading } from '@common/api/useApi';
import Spinner from '@common/basics/Spinner';
import ErrorMessage from '@common/basics/ErrorMessage';

import '@common/signing/sign-page.less';

const AdvancedSignatureSigning: FunctionComponent = () => {
  const navigate = useNavigate();
  const [translation, , , , language] = useI18n();
  const { eID } = useRequiredParams(['eID']);
  const signatureJobToSign = useUnsignedSignatureJob();
  const { setDisableScroll } = useToggleScrollContext();
  const { request } = useSigneringApi({
    dataFetcher: createSignicatIframeLink,
    urlArgs: [
      `${signatureJobToSign.links.createSignatureFrame}?eid=${eID}&lang=${language}`,
    ],
  });
  useEffect(() => {
    {
      setDisableScroll(true);
    }
    return () => setDisableScroll(false);
  });

  const goBackToJoblistPage = () => {
    navigate(`/${LOGGED_IN_HOME_PAGE_URL}`);
  };

  const changeSignStepUrl = (step: string) => {
    navigate(`../../${step}/`);
  };

  return (
    <section
      className={`full-width full-height${
        signatureJobToSign.sector === OrganizationSector.PUBLIC
          ? ' advanced-signature-section'
          : ''
      }`}
    >
      {signatureJobToSign.sector === OrganizationSector.PUBLIC && (
        <Heading
          sizeClass="hw-h2"
          headerText={translation.portal.signing.read_and_sign}
        />
      )}
      {isLoading(request.status) && <Spinner fullScreen={true} />}
      {request.status === ApiRequestStatus.SUCCESS && (
        <SignicatIframe
          successCallback={() => changeSignStepUrl('bekreftelse')}
          cancelCallback={goBackToJoblistPage}
          failureCallback={() => changeSignStepUrl('feil')}
          signicatIframeLink={request.result}
          jobSenderSector={signatureJobToSign.sector}
        />
      )}
      {request.status === ApiRequestStatus.FAILURE && (
        <ErrorMessage message={translation.common.electronic_id.iframe_error} />
      )}
      {signatureJobToSign.sector === OrganizationSector.PUBLIC && (
        <div className="advanced-signature__button-group">
          <Link className="hw-link" to={`../../velgid/`}>
            {translation.portal.signing.previous_step}
          </Link>
          <Link className="hw-link" to={`/${LOGGED_IN_HOME_PAGE_URL}`}>
            {translation.portal.signing.cancel}
          </Link>
        </div>
      )}
    </section>
  );
};

const AdvancedSignatureSigningWrapper = () => (
  <SignPage>
    <AdvancedSignatureSigning />
  </SignPage>
);

export default AdvancedSignatureSigningWrapper;
