import { FunctionComponent } from 'react';

import { useI18n } from '@common/language/i18n';
import forbrukerradet from '@images/logos/forbrukerradet_logo.webp';
import utleiemegleren from '@images/logos/utleiemegleren_blue.webp';
import manpower from '@images/logos/manpower.webp';
import lanekassen from '@images/logos/lanekassen_rgb_farger.webp';
import nrk from '@images/logos/nrk.webp';
import baerumKommune from '@images/logos/baerum-kommune.webp';
import helsebergen from '@images/logos/helsebergen.webp';
import vestreviken from '@images/logos/vestreviken.webp';
import bi from '@images/logos/bi-logo.webp';
import nav from '@images/logos/nav_rod.webp';
import frelsesarmeen from '@images/logos/frelsesarmeen.webp';
import ntnu from '@images/logos/ntnu-uten-slagord.webp';
import sio from '@images/logos/sio.webp';
import sparebank1 from '@images/logos/sparebank1.webp';
import utdanningsforbundet from '@images/logos/utdanningsforbundet.webp';

import './how-others-use.less';

const HowOthersUse: FunctionComponent = () => {
  const [translation] = useI18n();

  return (
    <article>
      <h2 className="hw-h2 hw-color-primary-darker hw-block--mb-medium-1 hw-block--mt-large-4--responsive text-center--mobile heading--mobile">
        {translation.common.frontpage.how_others_use.heading}
      </h2>
      <div className="customer-logos__wrapper">
        <div className="customer-logos__column">
          <img
            src={sio}
            alt="Logo: Studentsamskipnaden i Oslo"
            className="sio"
          />
          <img
            src={forbrukerradet}
            alt="Logo: Forbrukerrådet"
            className="forbruker"
          />
          <img
            src={bi}
            alt="Logo: Handelshøyskolen BI"
            className="logo--big bi"
          />
        </div>
        <div className="customer-logos__column">
          <img src={sparebank1} alt="Logo: Sparebank 1" />
          <img src={baerumKommune} alt="Logo: Bærum Kommune" />
          <img src={ntnu} alt="Logo: NTNU" className="ntnu" />
        </div>

        <div className="customer-logos__column">
          <img src={nav} alt="Logo: NAV" className="logo--big nav" />
          <img src={nrk} alt="Logo: NRK" className="logo--big nrk" />
          <img
            src={vestreviken}
            alt="Logo: Vestre Viken"
            className="vestre-viken"
          />
        </div>
        <div className="customer-logos__column">
          <img src={lanekassen} alt="Logo: Lånekassen" className="lanekassen" />
          <img
            src={utleiemegleren}
            alt="Logo: Utleiemegleren"
            className="logo--small utleie"
          />
          <img
            src={manpower}
            alt="Logo: Manpower"
            className="logo--small manpower"
          />
        </div>
        <div className="customer-logos__column">
          <img
            src={frelsesarmeen}
            alt="Logo: Frelsesarmeen"
            className="frelse"
          />
          <img
            src={helsebergen}
            alt="Logo: Helse Bergen"
            className="helse-bergen"
          />
          <img
            src={utdanningsforbundet}
            alt="Logo: Utdanningsforbundet"
            className="udf"
          />
        </div>
      </div>
    </article>
  );
};

export default HowOthersUse;
