import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  useNavigate,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import React, {
  useEffect,
  lazy,
  Suspense,
  FunctionComponent,
  StrictMode,
  PropsWithChildren,
  useCallback,
  useMemo,
} from 'react';
import { QueryClientProvider, useQueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { AppWithTracking } from '@common/analytics/Analytics';
import ServiceMessage from '@common/header/ServiceMessage';
import LandingPage from '../landingPage/LandingPage';
import LoginPage from '../landingPage/login/LoginToSignPage';
import PrivateLogin from '@common/login/PrivateLogin';
import IdPortenRedirect from '@common/idporten/IdPortenRedirect';
import PrivateLoginProcess from '@common/login/PrivateLoginProcess';
import { AppName } from '@common/analytics/appNames';
import { SESSION_TIMEOUT_URL } from '@common/session/sessionTimeoutUrls';
import SessionTimeout from '@common/login/SessionTimeoutPortal';
import DevMode from './DevMode';
import FeedbackMascot from '@common/feedback/FeedbackMascot';
import PortalHeader from './menu/PortalHeader';
import config from '@common/config';
import Spinner from '@common/basics/Spinner';
import { SessionStatus, Target } from '@common/session/session';
import { AppWithI18n } from '@common/language/i18n';
import Main from '@common/pageBuildingBlocks/Main';
import LoggedOutHeader from '../landingPage/LoggedOutHeader';
import { useTracking } from '@common/analytics/Analytics';
import CookieBanner from '@common/analytics/consent/CookieBanner';
import Footer, { FooterType } from '@common/footer/Footer';
import { usePageviewTracker } from '@common/analytics/PageviewTracker';
import SignatureJobsWrapper from './jobs/SignatureJobsWrapper';
import ChooseElectronicID from './signpage/ChooseElectronicID';
import AdvancedSignatureSigning from './signpage/AdvancedSignatureSigning';
import SigningUnsuccesfulConfirmation from './signpage/SigningUnsuccesfulConfirmation';
import AuthenticatedSignatureForPortalSignatureJob from './signpage/AuthenticatedSignatureForPortalSignatureJob';
import { HelpTopicUrl } from '@common/help/helpTopic';
import SendHelp from '@common/help/SendHelp';
import SignHelp from '@common/help/SignHelp';
import About from '@common/help/About';
import {
  enableErrorTracking,
  trackBreadcrumb,
} from '@common/errorHandling/errorTracking';
import AppErrorBoundary from '@common/errorHandling/AppErrorBoundary';
import NotFound from '@common/errorHandling/NotFound';
import NavigateExternal from '@common/navigation/NavigateExternal';
import { App } from '../apps';
import { subsituteIdsWithPlacedholders } from '@common/navigation/urlSubstitutions';
import AppWithSession, { useSession } from '@common/session/AppWithSession';
import { createQueryClient } from '@common/api/queryClient';
import hasAccessToSignerPortal from './login/hasAccessToSignerPortal';
import RedirectIfLoggedIn from '@common/login/RedirectIfLoggedIn';
import RedirectIfNotLoggedIn from '@common/login/RedirectIfNotLoggedIn';
import ConfirmationPage from './signpage/confirmation/ConfirmationPage';
import AppWithFocus from '@common/focus/AppWithFocus';
import AppWithScrollToggle, {
  useToggleScrollContext,
} from '@common/scroll/AppWithScrollToggle';
import { ABOUT_DIGITAL_SIGNATURES_URL } from '@common/help/helpUrls';
import AboutDigitalSignatures from './help/AboutDigitalSignatures';
import UnknownSessionStatus from '@common/session/UnknownSessionStatus';
import { IS_PROD } from '@common/environment';
import { HELP_URL } from '@common/help/helpUrls';

import '@less/main.less';
import './signer-portal-base.less';

export const LOGGED_IN_HOME_PAGE_URL = 'oppdrag/';

enableErrorTracking({ appName: 'portal' });

if (!IS_PROD) {
  setTimeout(() => {
    void import('@axe-core/react').then((axe) =>
      axe.default(React, ReactDOM, 1000)
    );
  }, 1000);
}

const HelpPage = lazy(() => import('@common/help/HelpPage'));
const Cookies = lazy(() => import('../privatePerson/consent/Cookies'));
const CookiesInfoForAccounts = lazy(
  () => import('@common/cookies/CookiesInfoForAccounts')
);

const PrivacyInfoWithAccount = lazy(
  () => import('@common/privacy/PrivacyWithAccount')
);
const PrivacyInfoWithoutAccount = lazy(
  () => import('@common/privacy/PrivacyWithoutAccount')
);
const TermsInfoForAccounts = lazy(
  () => import('@common/termsAndConditions/TermsInfoForAccounts')
);
const Terms = lazy(() => import('../privatePerson/consent/Terms'));

const queryClient = createQueryClient();

const PortalApp: FunctionComponent = () => {
  usePageviewTracker('');
  const { session } = useSession();
  const loggedIn = useMemo(() => hasAccessToSignerPortal(session), [session]);

  const { setCanShowCookieBanner } = useTracking();

  const { disableScroll } = useToggleScrollContext();

  useEffect(() => {
    if (loggedIn) {
      setCanShowCookieBanner(false);
    } else {
      setCanShowCookieBanner(true);
    }
  }, [loggedIn, setCanShowCookieBanner]);

  // Needed to still support old hash-router routes
  const location = useLocation();
  if (location.hash.startsWith('#/')) {
    trackBreadcrumb(
      `User redirected bc of #/ from location ${subsituteIdsWithPlacedholders(
        location.pathname + location.hash + location.search
      )}`
    );
    return <Navigate replace to={location.hash.replace('#/', '')} />;
  }

  const isInSigningProcess =
    location.pathname.includes('dokument') ||
    location.pathname.includes('signer');

  return (
    <div className={`app${disableScroll ? '--no-scroll' : ''}`}>
      {loggedIn ? (
        <PortalHeader inSigningProcess={isInSigningProcess} />
      ) : (
        <LoggedOutHeader app={App.SIGNATURE_PORTAL} />
      )}
      <Main>
        <ServiceMessage />
        {session.status === SessionStatus.UNKNOWN && <UnknownSessionStatus />}
        <Routes>
          <Route
            element={
              <RedirectIfLoggedIn
                to={`/${LOGGED_IN_HOME_PAGE_URL}`}
                isLoggedIn={hasAccessToSignerPortal}
              />
            }
          >
            <Route path="/" element={<LandingPage />} />
            <Route path="logginn">
              <Route index element={<LoginPage />} />
              <Route path="privat">
                <Route index element={<PrivateLogin />} />
                <Route
                  path=":eID"
                  element={
                    <PrivateLoginProcess target={Target.SIGNER_TARGET} />
                  }
                />
              </Route>
              <Route
                path="idporten/:securityLevel"
                element={<IdPortenRedirect target={Target.SIGNER_TARGET} />}
              />
            </Route>
          </Route>

          <Route path={`${SESSION_TIMEOUT_URL}`} element={<SessionTimeout />} />

          {config.isDevModeEnabled ? (
            <Route path="dev" element={<DevMode />} />
          ) : null}

          <Route path="cookies">
            <Route
              path="med-virksomhetskonto"
              element={
                <Suspense fallback={<Spinner />}>
                  <CookiesInfoForAccounts />
                </Suspense>
              }
            />
            <Route
              path="uten-virksomhetskonto"
              element={
                <Suspense fallback={<Spinner />}>
                  <Cookies />
                </Suspense>
              }
            />
          </Route>
          <Route path="personvern">
            <Route index element={<Navigate to="uten-virksomhetskonto" />} />
            <Route
              path="med-virksomhetskonto"
              element={
                <Suspense fallback={<Spinner />}>
                  <PrivacyInfoWithAccount />
                </Suspense>
              }
            />
            <Route
              path="uten-virksomhetskonto"
              element={
                <Suspense fallback={<Spinner />}>
                  <PrivacyInfoWithoutAccount />
                </Suspense>
              }
            />
          </Route>
          <Route path="vilkar">
            <Route
              path="med-virksomhetskonto"
              element={
                <Suspense fallback={<Spinner />}>
                  <TermsInfoForAccounts />
                </Suspense>
              }
            />
            <Route
              path="uten-virksomhetskonto"
              element={
                <Suspense fallback={<Spinner />}>
                  <Terms />
                </Suspense>
              }
            />
          </Route>
          <Route
            path={`${HELP_URL}/${ABOUT_DIGITAL_SIGNATURES_URL}`}
            element={<AboutDigitalSignatures />}
          />

          <Route
            path={`${HELP_URL}/*`}
            element={
              <Suspense fallback={<Spinner />}>
                <HelpPage />
              </Suspense>
            }
          >
            <Route
              path={`${HelpTopicUrl.aboutPosten}/:subTopic`}
              element={<About />}
            />
            <Route path={`${HelpTopicUrl.aboutPosten}/`} element={<About />} />
            <Route
              path={`${HelpTopicUrl.send}/:subTopic`}
              element={<SendHelp />}
            />
            <Route path={`${HelpTopicUrl.send}/`} element={<SendHelp />} />
            <Route
              path={`${HelpTopicUrl.sign}/:subTopic`}
              element={<SignHelp />}
            />
            <Route path={`${HelpTopicUrl.sign}/`} element={<SignHelp />} />
          </Route>

          <Route
            path="oppdrag"
            element={
              <RedirectIfNotLoggedIn
                to="/"
                isLoggedIn={hasAccessToSignerPortal}
              />
            }
          >
            <Route index element={<SignatureJobsWrapper />} />
            <Route path=":id">
              <Route path="avansert">
                <Route path="velgid" element={<ChooseElectronicID />} />
                <Route path="signer">
                  <Route path=":eID" element={<AdvancedSignatureSigning />} />
                </Route>
                <Route path="bekreftelse" element={<ConfirmationPage />} />
                <Route
                  path="feil"
                  element={<SigningUnsuccesfulConfirmation />}
                />
              </Route>
              <Route path="autentisert">
                <Route
                  path="dokument"
                  element={<AuthenticatedSignatureForPortalSignatureJob />}
                />
                <Route path="bekreftelse" element={<ConfirmationPage />} />
                <Route
                  path="feil"
                  element={<SigningUnsuccesfulConfirmation />}
                />
              </Route>
            </Route>
          </Route>
          <Route
            path="enkel-digital-signering"
            element={<NavigateExternal to={'/privat'} keepQueryParams={true} />}
          />
          <Route
            path="signer-arbeidsavtaler-elektronisk"
            element={
              <NavigateExternal to={'/virksomhet'} keepQueryParams={true} />
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Main>
      {!isInSigningProcess && <FeedbackMascot appName={AppName.SIGNER} />}
      <Footer
        footerType={loggedIn ? FooterType.LOGGED_IN : FooterType.NOT_LOGGED_IN}
        inSigningProcess={isInSigningProcess}
      />
      <CookieBanner />
    </div>
  );
};

const PortalAppWithSession: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const reactQueryClient = useQueryClient();
  const navigate = useNavigate();

  const logout = useCallback(() => {
    reactQueryClient.clear();
    navigate(`/${SESSION_TIMEOUT_URL}`);
  }, [navigate, reactQueryClient]);

  return (
    <AppWithSession
      logout={logout}
      placeholder={
        <main className="app">
          <Spinner fullScreen={true} />
        </main>
      }
    >
      {children}
    </AppWithSession>
  );
};

createRoot(document.getElementById('react') as HTMLElement).render(
  <StrictMode>
    <AppWithI18n>
      <AppErrorBoundary>
        <AppWithTracking>
          <BrowserRouter>
            <AppWithFocus>
              <QueryClientProvider client={queryClient}>
                <PortalAppWithSession>
                  <AppWithScrollToggle>
                    <PortalApp />
                  </AppWithScrollToggle>
                </PortalAppWithSession>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </AppWithFocus>
          </BrowserRouter>
        </AppWithTracking>
      </AppErrorBoundary>
    </AppWithI18n>
  </StrictMode>
);
